import { useEffect, useState } from "react";
import { getCookie } from "helpers";
import { useRouter } from "next/router";

const useContainerWidget = (props) => {
  const { 
    arrayLength = 0, 
    containerName,
    dynamicType = 'default', 
    title,
    title1,
    link1,
    title2,
    link2,
    title3,
    link3,
    title4,
    link4, 
  } = props;
  const initialIndexNews = Array.from({ length: arrayLength }, (_, index) => index);
  const [firstRender, setFirstRender] = useState(false);
  const [loading, setLoading] = useState(['abTest','dynamicNews'].includes(dynamicType) ?? false);
  const [indexNews, setIndexNews] = useState(initialIndexNews);
  const router = useRouter();
  
  let initMarfeelTitle = title
  ? `${containerName}-` +
    title
      ?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase()
  : containerName;
  const [marfeelTitle, setMarfeelTitle] = useState(initMarfeelTitle);
  
  const titlesExtras = [];
  if (title1 && link1) titlesExtras.push({ title: title1, link: link1 });
  if (title2 && link2) titlesExtras.push({ title: title2, link: link2 });
  if (title3 && link3) titlesExtras.push({ title: title3, link: link3 });
  if (title4 && link4) titlesExtras.push({ title: title4, link: link4 });

  const formatUrlDynamic = (params, paramsDynamic = "") => {
    if (router.query.app === "true" || getCookie("app") === "true") {
      return;
    }
    props?.content?.forEach((element, index) => {
      if(element.url.includes(params) || (paramsDynamic !== "" && element.url.includes(paramsDynamic))) return;
      if (paramsDynamic && index > (arrayLength - 1)) {
        element.url = element.url + paramsDynamic;
      } else {
        element.url = element.url + params;
      }
    });
  }

  useEffect(() => {
    if (props.isPreview) return;
    let newsReads = JSON.parse(localStorage.getItem("news_reads")) || [];
    const isRandomDynamicNews = getCookie("randomDynamicNews");
    if (isRandomDynamicNews === "0" && dynamicType === "abTest") {
      formatUrlDynamic("?fijo");
      setLoading(false);
      return
    };  
    if((dynamicType === "abTest" && isRandomDynamicNews === "1") || dynamicType === "dynamicNews") {
      if(dynamicType === "abTest") {
        formatUrlDynamic("?dinamico","?dinamico_rotacion");
      }
      setMarfeelTitle(marfeelTitle + "-dynamic");
    }      
    if (newsReads.length > 0 && ['abTest','dynamicNews'].includes(dynamicType)) {
      const contentWithoutNewsReads = props.content
        .map((item, index) =>
          !newsReads.some((newsRead) => newsRead.id === item.lilaNewsID)
            ? index
            : -1
        )
        .filter((index) => index !== -1);
      const contentWithNewsReads = props.content
        .map((item, index) =>
          newsReads.some((newsRead) => newsRead.id === item.lilaNewsID)
            ? index
            : -1
        )
        .filter((index) => index !== -1);
      const contentSorted = [
        ...contentWithoutNewsReads,
        ...contentWithNewsReads,
      ];
      const newContent = contentSorted.slice(0, arrayLength);      
      setIndexNews(newContent);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    } else {
      setLoading(false);
    }
  }, [indexNews]);

  return {
    indexNews,
    loading,
    marfeelTitle,
    titlesExtras,
  };
};

export default useContainerWidget;
