import { Service } from "models/Service";
import { dataMetaGames } from 'helpers';

export class Games extends Service {
  constructor() {
    super({
      adsPath: "juegos",
      meta: {
        description: `Juegos - Clarin.com`,
        keywords: '',
        title: `Juegos - Clarin.com`,
        url: `juegos`, 
        extra: [],
      },
      pageType: "juego",
      section: "juegos",
      sectionName: "Juegos",
      sectionAds: "juegos",
      template: "Games",
      title: `Juegos`,
      widthFull: true,
      slug: "juegos-clarin",
      titleHeader:"Juegos",
      titleHeaderLink:"/juegos",
      autorefresh : false,
      bannerLogo: true,
    });    
  }

  parseData = async (query) => {    
    const urlQuery = `${query?.[0] !== undefined ? query[0] : ''}${query?.[1] !== undefined ? '/'+query[1]+'/' : ''}${query?.[2] !== undefined ? query[2] : ''}`;
    const metaArray = dataMetaGames(urlQuery);
    const slug = this.getSlug();
    const notes = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/latest/?type=lilanews&tag=${slug}&limit=9`);
    query
    this.setData({
      notes: notes?.data?.items ?? [],
      query
    });    
    const titleMeta = metaArray.find(meta => meta.property === 'og:title');
    const titleContent = titleMeta ? titleMeta.content : "juegos";
    if (query?.[2]) {
      const urlNormalize = query[2].normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/_|#|-|@|<>/g, "")
      .toLowerCase();
      this.setSectionAds(urlNormalize);
      this.setTitleHeaderLink("/juegos");
    }
    this.setMeta(metaArray)
    this.setTitle(titleContent);
  }
}