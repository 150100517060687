import { Service } from 'models/Service'
import axios from 'axios'
import { getStringBetween, parseFile, authorSlug } from './helpers'

export class Joke extends Service {
  constructor() {
    super({
      adsPath: 'servicios/home',
      meta: {
        description: `Humor - Clarín.com`,
        keywords:
          'noticias, política, deportes, economía, internacionales, mundo, espectáculos, Argentina, sociedad, ciudades, policiales, internet, tecnología, infografías, fotos, videos, audios, multimedia, clima, claringrilla, humor, blogs, mapas, archivo, edición impresa, ',
        title: `Humor`,
        url: 'humor',
        extra: [],
      },
      section: 'humor',
      sectionName: 'Humor',
      template: 'Humor',
      title: 'Humor',
      slug: 'humor',
      titleHeader: 'Humor',
      breadcrumb: [],
    })
  }

  async getFetchText() {
    const proxy = {
      protocol: process.env.PROXY_ALT_PROTOCOL || 'http',
      host: process.env.PROXY_ALT_HOST || '192.168.248.178',
      port: process.env.PROXY_ALT_PORT || 5889,
    }
    //console.log('proxy', proxy)
    try {
      const { data } = await axios.get(
        process.env.CHISTES_CDN,
        {
          proxy: proxy,
        },
      )
      return data
    } catch (error) {
      //console.log('error humor', error)
      return null
    }
  }

  parseData = async (query) => {
    /** GET CONTENTS START */
    const resp_data = await this.getFetchText()
    if (!resp_data) {
      this.setData({
        jokeFeatured: {},
        jokes: [],
        error: 'fetch chistes null',
      })
      return
    }

    let uniqueId = 0 // uniqueId
    let jokes = []

    // puede venir la page , sino vale 1
    let page = 1
    switch (query.length) {
      case 3:
        page = query[1] == 'page' ? parseInt(query[2]) : page
        break
      case 4:
        page = query[2] == 'page' ? parseInt(query[3]) : page
        break
      case 5:
        page = query[3] == 'page' ? parseInt(query[4]) : page
        break
      default:
        break
    }

    // puede venir la tira
    const tira =
      query[2] && query[2] !== 'page' && isNaN(query[2]) ? query[2] : null

    // puede venir el author para filtrar los chistes
    let author = query[1] && query[1] !== 'page' ? query[1] : ''

    let authorModified = authorSlug(author.trim().toLowerCase())

    switch (authorModified) {
      case 'el':
        authorModified = 'el-nino-rodriguez'
        break
      case 'ninorodriguez':
        authorModified = 'el-nino-rodriguez'
        break
      case 'maria':
        authorModified = 'maria-ramirez'
        break
      default:
        break
    }

    //parse data
    try {
      if(process.env.CHISTES_CDN === 'http://chistes.clarin.com/chistes.html') { 
        jokes = resp_data
            .split('<body>')[1]
            .split('<p>')[1]
            .split('<br>');
      } else {
          jokes = resp_data.split('<br>');
      }
      jokes = jokes
        .filter((elem) => elem.includes('.jpg'))
        .reduce((memo, joke) => {
          const id = uniqueId
          uniqueId++
          const parseJoke = getStringBetween(
            joke.replace(/(?:\r\n|\n|\r|\t|&nbsp;|\s|\||`|--|(\[.*\]))/g, ''),
            '<ahref=.',
            '">',
          ).split('/')
          const objectJoke = {
            jokeDate: `${parseJoke[1]}-${parseJoke[2]}-${parseJoke[3]}`,
            jokeFilesCustom: [
              parseFile(
                parseJoke[4],
                id,
                `${parseJoke[3]}-${parseJoke[2]}-${parseJoke[1]}`,
                `${parseJoke[1]}/${parseJoke[2]}/${parseJoke[3]}`,
              ),
            ],
          }
          const regexJokeDate = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[01])$/;
          if (!regexJokeDate.test(objectJoke.jokeDate)) {
              console.log('error en la fecha (humor)', objectJoke.jokeDate);
              return memo;
          }
          /* const partsOfDate = objectJoke.jokeDate.split('-');
          if (partsOfDate.includes('0')) {
              console.log('error en la fecha', objectJoke.jokeDate);
              return memo;
          } */
          let existsJoke = memo.filter(
            (elem) => elem.jokeDate === objectJoke.jokeDate,
          )
          if (existsJoke.length === 0) {
            memo.push(objectJoke)
            return memo
          }
          existsJoke[0].jokeFilesCustom = existsJoke[0].jokeFilesCustom.concat(
            objectJoke.jokeFilesCustom,
          )
          return memo
        }, [])
        .sort(
          (a, b) =>
            new Date(b.jokeDate).getTime() - new Date(a.jokeDate).getTime(),
        )
    } catch (error) {
      console.error({ error })
      jokes = []
    }

    /** FILTROS START */
    //author = 'erlich';
    if (author !== '') {
      jokes = jokes.map((joke) => {
        const jokeFiles = joke.jokeFilesCustom.filter((jf) => {
          if (!tira) return jf.authorSlug == authorModified
          else {
            const dataTira = tira.split('-')
            const dataTiraName = []
            for (let i = 0; i < dataTira.length - 1; i++) {
              dataTiraName.push(dataTira[i])
            }
            return (
              jf.authorSlug == authorModified &&
              dataTiraName.join('-') == jf.nameSlug
            )
          }
        })
        return {
          ...joke,
          ['jokeFilesCustom']: jokeFiles,
        }
      })
    }
    jokes = jokes.filter((joke) => joke.jokeFilesCustom.length > 0)

    //joke featured si hay tira (antes de la paginacion para que no se pierda el featured)
    let jokeFeatured = null
    if (tira) {
      const dataTira = tira.split('-')
      jokes.forEach((joke) => {
        joke.jokeFilesCustom.forEach((jf) => {
          if (jf.imageId == Number(dataTira[dataTira.length - 1])) {
            jokeFeatured = jf
            return
          }
        })
        if (!!jokeFeatured) return
      })
    }

    //pagination
    const size = 8
    const total = jokes.length
    const pages = Math.ceil(total / size)
    const offset = (page - 1) * size
    const limit = offset + size
    jokes = jokes.slice(offset, limit)
    /** FILTROS END */

    //joke featured si no hay tira
    if (!jokeFeatured) {
      jokes.forEach((joke, i) => {
        if (joke.jokeFilesCustom.length > 0 && !jokeFeatured) {
          jokeFeatured = joke.jokeFilesCustom.shift()
        }
      })
      if (page <= pages && jokes[0].jokeFilesCustom.length == 0) {
        jokes.splice(0, 1)
      }
    }

    //heading
    if (author !== '' && jokeFeatured?.author) {
      this.breadcrumb = [
        {
          title: 'Humor',
          url: '/humor',
        },
        {
          title: jokeFeatured.author,
          url: '/humor/' + jokeFeatured.authorSlug,
        },
      ]
    }

    // RETURN RESULT
    this.setData({
      jokeFeatured: jokeFeatured ?? {},
      jokes,
      pages: pages > 20 ? 20 : pages,
      total: total > 200 ? 200 : total,
      size,
      offset,
      subTitle: !tira ? 'LOS ÚLTIMOS' : 'RELACIONADOS',
      error: false,
    })

    /** GET CONTENTS END */
  }
}
