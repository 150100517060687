import { compareDates, hashWord, normalizeWord } from "helpers";
import { Service } from "models/Service";
import claringrilla1 from "public/json/claringrilla1";

export class GridGame extends Service {
  constructor() {
    super({
      adsPath: "juegos",
      meta: {
        description: `Jugá a la Claringrilla de hoy gratis en Clarín.com.`,
        keywords: 'claringrilla',
        title: `¡Jugá a la Claríngrilla online!`,
        url: `claringrilla`, 
        extra: [],
        urlImage: `https://www.clarin.com/images/collections/servicios/claringrilla___65ce61853ef70b001c44f1ba.png`
      },
      pageType: "juego",
      section: "claringrilla",
      sectionAds: "claringrilla",
      sectionName: "Juegos",
      template: "GridGame",
      title: `Claringrilla`,
      widthFull: true,
      slug:"juegos-clarin",
      titleHeader:"Juegos",
      titleHeaderTag: "p",
      showGoolgeOneTap: false,
      autorefresh: false,
      bannerLogo: true,
    });    
  }
  
  parseData = async (query) => {    
    const slug= this.getSlug()
    const notes = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/latest/?type=lilanews&tag=${slug}&limit=9`);
    const claringrillaId = query?.[1] || '';
    const data = claringrillaId === '00001' ? claringrilla1 : (await this.fetchData(`${process.env.NEXT_PUBLIC_API_CONTENTS}/api/claringrilla/detail/${claringrillaId}`)).data;
    const itemDate = data?.item?.date ? new Date(data?.item?.date.replace("T", " ").replace("Z", "")) : new Date();      
    let solution = compareDates(itemDate, new Date());
    const words = data?.item?.items?.map(item => ({
      ...item,
      length: item.word.length,
      word: hashWord(normalizeWord(item.word))
    })) ?? [];
    this.setData({
      publicId: data?.item?.id,
      privateId: data?.item?._id,
      dateGame: data?.item?.date,
      proverb: data?.item?.proverb,
      syllables: data?.item?.syllables, 
      previousSolutionId: data?.item?.previousSolutionId,
      previousSolution: data?.item?.previousSolution,
      items: !solution ? data?.item?.items : [],
      solution,
      words,
      notes: notes?.data?.items ?? []
    });
  }
}