const dataZodiacPredictions = [
    {
        "signZodiac": "Aries",
        "fechaDesde": "0321",
        "fechaHasta": "0420",
        "description": "Predicciones 2025 de Aries para el amor y el dinero",
        "prediction": 
        [
            {
                "title": "Horóscopo 2025 para Aries",
                "content": "Las predicciones de 2025 para Aries les auguran a los inquietos carneros un año movilizante. Así lo cuenta la astróloga Lourdes Ferro, quien agrega que habrá varios tránsitos planetarios destacados que dibujarán un panorama único para cada signo zodiacal.</p><p>Ferro aconseja que los carneros aprovechen las grandes oportunidades que llegarán este 2025. En general, su energía creativa crecerá y, si escuchan con atención, les abrirá a nuevas perspectivas sobre el mundo y sobre sí mismos.</p><p>Marte, el regente de Aries, comenzará el 2025 retrógrado en Cáncer. Un indicador de que será mejor no apurar algunas iniciativas. En vez de eso, el planeta les pedirá reflexión y mesura hasta el 24 de febrero, cuando volverá a su fase directa. De allí en más, avanzará firme el resto del 2025.</p><p>Ferro destaca que Saturno y Neptuno ingresarán a este signo a lo largo del año y también dejará una huella en la vida de los carneros.</p><p>A la par de estos movimientos, el planeta que dictará buena parte del clima anual será Júpiter.</p>"
            },
            {
                "title": "Predicciones 2025 de Aries para el amor y el dinero la primera mitad del año",
                "content": "<p>La primera mitad del año Júpiter, el “gran benefactor”, se ubicará en Géminis. Desde allí, tendrá un mensaje para los arianos. Ferro explica que el paso de este planeta por Géminis “hará que su mente se expanda y sus horizontes se amplíen”. También sostiene que esta mitad del año se enfocará su energía en objetivos concretos.</p><p>El último eclipse solar en Aries se perfeccionará junto a la Luna nueva en su signo el 29 de marzo de 2025. Un día después, el 30 de marzo, será Neptuno el que ingresará a su signo. Allí se quedará por casi siete meses. A los arianos, que hace muchos años no recibían la visita neptuniana, les hará más soñadores y “les va a permitir que piensen en cosas que, hasta ahora, ni siquiera imaginan”, adelanta la astróloga.</p><p>El 25 de mayo de 2025, Saturno hará su primera visita a Aries en más de 25 años. Contrario a lo que se pensaría, que este planeta llegue a su signo no los abrumará de exigencias. Saturno en Piscis, signo de cierre de ciclos, les demandaba mucho más. A partir de esta fecha, Ferro explica que la exigencia y los límites serán frontales y más claros. “No es lo mismo tener el enemigo en la espalda que tenerlo enfrente y saber con quién estás luchando”, afirma.</p><p>El 9 de junio, Júpiter se despide de Géminis. Hasta entonces, la especialista dice que habrá permitido que los arianos se comuniquen mejor, que se acerquen a otras personas. Además, habrá dejado un terreno favorable para que arreglen trámites y documentaciones.</p>"
            },
            {
                "title": "Predicciones 2025 de Aries para el amor y el dinero la segunda mitad del año",
                "content": "<p>La segunda parte del 2025 estará modelada por el ingreso de Júpiter en Cáncer. El 9 de junio se abrirá para Aries una temporada para que conecten con sus espacios privados y personales. Ferro augura que las negociaciones inmobiliarias y las mudanzas están muy bien aspectadas a esta altura del año para ustedes.</p><p>En julio, Saturno y Neptuno empezarán a retrogradar en su signo. El primero lo hará hasta volver a Piscis, el 1 de septiembre. El segundo tardará un mes más y regresará al signo de los peces el 22 de octubre. Hasta entonces, ambos planetas les pedirán revisión y análisis de sus acciones.</p><p>El 7 de octubre será la Luna llena en su signo por única vez en el año. Ferro explica que la lunación les impulsará a tomar acciones con valentía, algo que a ustedes no les cuesta demasiado.</p><p>Para Ferro, si el proyecto de un ariano está en comunicar, vender, negociar o viajar, este 2025 les sonreirá.</p>"
            }
        ]
    },
    {
        "signZodiac": "Tauro",
        "fechaDesde": "0421",
        "fechaHasta": "0520",
        "description": "Predicciones 2025 de Tauro para el amor y el dinero",
        "prediction": 
        [
            {
                "title": "Horóscopo 2025 para Tauro",
                "content": "<p>Las predicciones de 2025 para Tauro anuncian muchos movimientos, especialmente en el plano financiero. La astróloga Lourdes Ferro destaca el rol de Urano, que ha estado transformando al signo de tierra desde 2018. Descubrí cuáles serán las fechas y los tránsitos planetarios más importantes para Tauro este año.</p><p>“Hace mucho tiempo, Urano revolucionó su vida, destruyó su estructura, desarmó su estabilidad, los sacudió —explica Ferro—. Ahora los deja, entra a su sector de la economía”. En ese sentido, también les recuerda que sus recursos y su economía no está solo en el dinero que tienen, sino en aquello que saben hacer.</p>"
            },
            {
                "title": "Predicciones 2025 de Tauro para el amor y el dinero la primera mitad del año",
                "content": "<p>El primer movimiento destacado ocurre el 31 de enero, cuando Urano, que empieza el año retrógrado en su signo, volverá a su fase directa. Allí avanzará hasta el 7 de julio, cuando cambiará al signo de Géminis. Será una reconfiguración de los valores materiales.</p><p>Del 1 de enero al 9 de junio de 2025, Júpiter en Géminis ampliará las oportunidades económicas de los taurinos. La astróloga señala que “el gran benefactor” les abrirá nuevas puertas al crecimiento: “Estarán desarrollando una visión clara y realista de sus metas financieras, enfocándose en inversiones sólidas y decisiones inteligentes”.</p><p>A partir del 1 de marzo de 2025, Venus, su planeta regente, retrogradará por primera vez desde julio de 2023 (las retrogradaciones venusianas se dan cada 20 meses). Lo hará en el signo de Aries, pero su marcha atrás le hará volver a Piscis. El tránsito terminará el 13 de abril de 2025. Unas semanas después, el 27 de abril, vivirán su Luna nueva anual, ideal para sembrar intenciones y objetivos.</p><p>El 6 de junio, Venus finalmente les visitará. Se mantendrá en su casa hasta el 4 de julio de 2025 y potenciará sus vínculos. Recuerden que este es el planeta del amor y la belleza.</p>"
            },
            {
                "title": "Predicciones 2025 de Tauro para el amor y el dinero la segunda mitad del año",
                "content": "<p>El 9 de junio de 2025, el ingreso de Júpiter en Cáncer significará para ustedes la llegada de ideas brillantes e intensas, pero deberán dirigir sus pasos con firmeza hasta materializarlas. Ferro indica que tendrán la oportunidad de convertir sus pensamientos en acciones concretas que se conviertan en un beneficio para ustedes y las personas a su alrededor.</p><p>La astróloga destaca el impacto que tendrá en Tauro la visita de Plutón en Acuario. Aunque es un movimiento que empezó en el 2024, sus efectos sentirán a lo largo de los próximos 20 años. Ferro explica que mientras esté en Acuario, Plutón, el regente de su opuesto complementario (Escorpio), va a dar un movimiento importante a la cruz fija a la que Tauro pertenece. “Revolución sería la palabra clave, tendrán que adaptarse al cambio”, precisa.</p><p>El 7 de julio, Urano abandona su signo por varios meses. El planeta de la innovación y la originalidad transformó lo que entendíamos por recursos, las estructuras sobre las que se levantaban los bienes y la materialidad. Al moverse a Géminis después de tantos años llevará su espíritu revolucionario al campo de la comunicación, al menos por unos meses.</p><p>El 5 de noviembre de 2025 se dará la Luna llena en Tauro, una lunación que cierra el ciclo de seis meses que hayan iniciado en abril. Dos días después, el 7 de noviembre, Urano retrógrado volverá por última vez a su signo. Los perseverantes y tenaces toros del zodiaco podrán usar este movimiento para revisar cómo administran los dones y los recursos que el 2025 puso a su disposición.</p>"
            }
        ]
    },
    {
        "signZodiac": "Géminis",
        "fechaDesde": "0522",
        "fechaHasta": "0621",
        "description": "Predicciones 2025 de Géminis para el amor y el dinero",
        "prediction": 
        [
            {
                "title": "Horóscopo 2025 para Géminis",
                "content": "<p>Las predicciones de 2025 anuncian “un año de expansión y revolución” para Géminis. Júpiter viene expandiendo su mundo desde mediados del 2024 y continuará haciéndolo hasta junio de 2025.<p></p>La astróloga Lourdes Ferro enumera cuáles serán las fechas y los tránsitos planetarios más importantes para Géminis en lo que considera como “su año”. En principio, aclara que no será siempre fácil, puesto que enfrentarán desafíos y oportunidades en igual medida.<p></p>¿A qué se debe este cambio? A la presencia de Júpiter y de Urano visitando su signo. Júpiter define las grandes energías de un año y, la primera mitad del 2025, amplificará a todo el zodiaco las características geminianas. Luego, de la mano de Urano su energía revolucionará las comunicaciones.</p>"
            },
            {
                "title": "Predicciones 2025 de Géminis para el amor y el dinero la primera mitad del año",
                "content": "<p>Con la visita de Júpiter, del 1 de enero al 9 de junio de 2025 Géminis contagia a los doce signos toda su curiosidad y su adaptabilidad. Ferro explica que se trata de “un período perfecto para nuevos comienzos y proyectos creativos”. Es más, aconseja a los inquietos geminianos establecer metas realistas, ponerse de pie y avanzar en un ciclo que durará doce años. En mayo de 2036 el planeta de la buena fortuna les visitará otra vez.<p></p>El 27 de mayo de 2025 vivirán la Luna nueva en Géminis, una fecha ideal para fijar nuevos objetivos.<p></p>¿Qué pueden esperar de su planeta regente? Mercurio estará retrógrado tres veces en el 2025. En todas, iniciará su aparente marcha atrás desde los signos del elemento fuego: en abril, retrocederá desde Aries a Piscis; en julio, será sobre el signo de Leo; en noviembre, de Sagitario regresará a Escorpio.</p>"
            },
            {
                "title": "Predicciones 2025 de Géminis para el amor y el dinero la segunda mitad del año",
                "content": "<p>“A partir del 9 de junio de 2025 el enfoque de Géminis pasará a su economía”, anuncia Ferro. Para entonces, Júpiter saldrá de su signo y se ubicará en Cáncer. Aunque el planeta les deja, la astróloga precisa que las ventas y negociaciones “estarán en su mejor momento” y se presentarán varias oportunidades de aumentar sus ingresos.<p></p>La siguiente fecha importante para Géminis sucederá un mes después. Del 7 de julio al 7 de noviembre de 2025, Urano, el planeta de la revolución y la originalidad, se ubicará en su signo. La astróloga lo describe como “un torbellino de energía disruptiva y emocionante”. Tocarán a su puerta diversas situaciones que les animen a salir de los convencionalismos en la forma de comunicarse y de pensar.<p></p>Antes de que termine el 2025, la Luna llena del 4 de diciembre iluminará todos los proyectos que los geminianos hayan desarrollado, aquello que visualizaron con su Luna nueva de hace seis meses.</p>"
            }
        ]
    },
    {
        "signZodiac": "Cáncer",
        "fechaDesde": "0622",
        "fechaHasta": "0723",
        "description": "Predicciones 2025 de Cáncer para el amor y el dinero",
        "prediction": 
        [
            {
                "title": "Horóscopo 2025 para Cáncer",
                "content": "<p>Las predicciones de 2025 designan a Cáncer como el signo más importante la segunda mitad del año. Los cangrejos, sensibles y apegados a la tradición, girarán de una mirada retraída y reflexiva a una llena de proyectos conforme avance el año.</p><p>Frente a doce meses muy cambiantes, la astróloga Lourdes Ferro señala cuáles serán las fechas y los tránsitos planetarios que impactarán más al signo de Cáncer.</p>"
            },
            {
                "title": "Predicciones 2025 de Cáncer para el amor y el dinero la primera mitad del año",
                "content": "<p>Del 1 de enero al 9 de junio de 2025, Júpiter “estará a sus espaldas”, transitando los últimos grados del signo de Géminis. Por eso, la astróloga afirma que los primeros seis meses es probable que se sientan aturdidos y se despierten en ustedes inseguridades ocultas.</p><p>Ferro aconseja que durante este período los nativos de Cáncer se mantengan resguardados, que reserven sus energías para el periodo que está por venir. “No será este el momento de accionar, sino de ordenar su interior con calma”, precisa.</p><p>La astróloga indica que el 25 de mayo, cuando Saturno deje a Piscis para ingresar a Aries, se traducirá en “una sensación de alivio inmenso en tu área profesional”, aunque llevará la exigencia a tu área de los logros.</p>"
            },
            {
                "title": "Predicciones 2025 de Cáncer para el amor y el dinero la segunda mitad del año ",
                "content": "<p>Los meses de junio y julio condensarán las energías para su signo: los planetas y el Sol les visitarán como un vendaval. Mercurio entrará a su signo el 8 de junio y será un buen preámbulo para el movimiento que producirá un vuelco en el resto de su año.</p><p>Al día siguiente, el 9 de junio, Júpiter ingresará a Cáncer. Ferro indica que el movimiento marcará “un tiempo más emocional, enfocado en fortalecer nuestras relaciones más cercanas y encontrar estabilidad en lo que realmente nos hace sentir en casa”.</p><p>A lo largo de los últimos seis meses del 2025, el “gran benefactor” les brindará una energía revitalizadora, un momento clave para que proyecten con confianza sus objetivos y expandan su propósito.</p><p>El 20 de junio, con el solsticio de invierno, el Sol entrará en su signo. Cuatro días después vivirán la Luna nueva en Cáncer, el momento lunar del año para trazar nuevos planes.</p><p>Del 31 de julio al 25 de agosto de 2025, Venus estará en Cáncer y orientará su intuitiva energía hacia sus vínculos, la belleza, el amor y el romance. Usen este tránsito para poner foco en estos temas.</p><p>El 11 de noviembre de 2025 hasta marzo del 2026 Júpiter retrogradará en su signo y cambiará el ritmo vertiginoso para pedirle a todo el zodiaco que opte por llevar muchos de sus vínculos a revisión.</p>"
            }
        ]
    },
    {
        "signZodiac": "Leo",
        "fechaDesde": "0724",
        "fechaHasta": "0823",
        "description": "Predicciones 2025 de Leo para el amor y el dinero",
        "prediction": 
        [
            {
                "title": "Horóscopo 2025 para Leo",
                "content": "<p>Las predicciones de 2025 para Leo anuncian un año marcado por las colaboraciones y las alianzas. Con su brillo característico, los leoninos se verán en el desafío de compartir los reflectores y los logros con otros.</p><p>La astróloga Lourdes Ferro señala cuáles serán las fechas y los tránsitos planetarios más relevantes para el signo de Leo. “Libertad” será la palabra clave que los guiará gracias a la energía del elemento aire, que aviva su llama innata; especialmente por la presencia de Plutón en su signo opuesto, Acuario.</p>"
            },
            {
                "title": "Predicciones 2025 de Leo para el amor y el dinero la primera mitad del año",
                "content": "<p>Del 1 de enero al 9 de junio de 2025, Júpiter desde Géminis “hará que sus metas y visiones para el futuro estén en pleno cambio, ofreciéndoles una visión más amplia de sus deseos”, afirma la astróloga.</p><p>Ferro advierte también que los primeros seis meses vivirán un periodo en el que las alianzas y colaboraciones jugarán un papel vital con socios, parejas o grupos cercanos. ¿La clave? “Adaptarse, aprender de los demás y ajustar sus aspiraciones según las oportunidades que surjan”, dice.</p><p>El 12 de febrero les iluminará la Luna llena en Leo. Su área de pareja seguirá movilizada y en primera fila para que se atrevan a construir con otros.</p><p>En general, Ferro estima que en el 2025 los leoninos podrán crecer en todos los ámbitos, tanto los personales como los profesionales. Para las personas de Leo que estén solteras podría aparecer una pareja.</p><p>El 18 de abril de 2025, Marte, el planeta guerrero, los visita. Con su energía impetuosa y luchadora avivará su fuego. Podrán aprovecharlo hasta el 16 de junio de 2025.</p>"
            },
            {
                "title": "Predicciones 2025 de Leo para el amor y el dinero la segunda mitad del año",
                "content": "La segunda mitad del año también traerá sorpresas para ustedes. El 9 de junio de 2025, Júpiter pasará a Cáncer y, en su caso, les animará a prepararse y fijar la atención en “bajar un poco las revoluciones”, después de todo el ajetreo y la polvareda.</p><p>El 22 de julio, el Sol dará inicio a su temporada y, dos días más tarde, recibirán también la energía de la Luna nueva en su signo. Aprovechen la lunación para renacer y brillar con una identidad renovada.</p><p>El 25 de agosto de 2025, con el Sol ya en Virgo, recibirán la sensualidad de Venus. El planeta del amor les llenará de magnetismo hasta el 19 de septiembre.</p><p>En resumen, Ferro vaticina que Leo “vivirá un 2025 que les va a llenar de libertad, de oportunidades en el amor y, sobre todo, de crecimiento y desarrollo. ¡A brillar como lo hacen siempre!”. </p>"
            }
        ]
    },
    {
        "signZodiac": "Virgo",
        "fechaDesde": "0824",
        "fechaHasta": "0923",
        "description": "Predicciones 2025 de Virgo para el amor y el dinero",
        "prediction": 
        [
            {
                "title": "Horóscopo 2025 para Virgo",
                "content": "<p>Las predicciones de 2025 anuncian un año que pondrá a prueba la perseverancia de Virgo. Así lo adelanta la astróloga Lourdes Ferro, quien también precisa que dos de los tránsitos planetarios más relevantes para los virginianos vendrán de la mano de Piscis, su signo opuesto complementario. El “tema central” del 2025 lo dará Júpiter, primero en Géminis y luego en Cáncer.</p><p>El regente de Virgo, Mercurio, iniciará y terminará el 2025 en Sagitario. En su recorrido anual, Mercurio estará retrógrado tres veces: en marzo, en julio y en noviembre. La Luna les sonreirá este 2025 con dos Lunas nuevas.</p><p>El carácter de Virgo, detallista, metódico, servicial y cauteloso, recibirá durante buena parte del 2025 las influencias de Saturno y de Neptuno (ambos en Piscis). Cuáles serán las fechas y los tránsitos planetarios más importantes y qué influencia tendrá el cambio de nodos lunares.</p>"
            },
            {
                "title": "Predicciones 2025 de Virgo para el amor y el dinero la primera mitad del año",
                "content": "<p>Desde el 1 de enero hasta el 9 de junio de 2025, Júpiter en Géminis generará en los virginianos “un fuerte sentido de perseverancia relacionado a su área laboral”, dice la astróloga.</p><p>Un movimiento menor pero no menos impactante para Virgo será el cambio del eje nodal el 29 de enero de 2025. El eje son los dos puntos de coincidencia entre la órbita de la Luna y la de la Tierra. Saldrán del eje Aries / Libra para entrar al eje Piscis / Virgo, donde ocurrirán tres de los cuatro eclipses del 2025.</p><p>De hecho, el 14 de marzo de 2025 vivirán la Luna llena y eclipse lunar total en Virgo. Ferro lo describe como un portal que se abrirá para que evalúen los procesos, su manera de ordenarse y de presentarse al mundo. Sentirán sus efectos hasta dos semanas después.</p><p>De enero hasta el 25 de mayo de 2025, Saturno en Piscis, como un riguroso maestro, habrá formado una especie de “nube que no les permitía ver las cosas como verdaderamente eran”, dice la especialista. Pero después de ese día, con el planeta en Aries, la experta adelanta que verán con mayor claridad muchos asuntos. </p>"
            },
            {
                "title": "Predicciones 2025 de Virgo para el amor y el dinero la segunda mitad del año",
                "content": "El 9 de junio de 2025, Júpiter pasará a Cáncer. Lourdes Ferro vaticina que el ingreso “traerá nuevas amistades a sus vidas, personas que les inspirarán y motivarán a ser la mejor versión de ustedes mismas”.</p><p>El consejo estará en abrirse a esa posibilidad, pues “estos nuevos contactos les ofrecerán oportunidades inesperadas”. La astróloga les aconseja ¡soñar en grande! y establecer nuevas metas con el apoyo de una red sólida.</p><p>Del 7 de julio al 7 de noviembre de 2025, Urano en Géminis les invitará a innovar y adaptarse, especialmente en su área profesional.</p><p>El 22 de agosto, el Sol se posará sobre su signo y dará inicio a su temporada. Al día siguiente, será la primera Luna nueva en Virgo, un comienzo de ciclo para que fijen intenciones y deseos.</p><p>El 19 de septiembre de 2025, Venus les visitará y llenará la energía del amor y de los vínculos de un espíritu meticuloso, servicial y detallista. El segundo novilunio en Virgo y el último eclipse del año serán el 21 de septiembre, un día antes del equinoccio de primavera (para el hemisferio sur). Habrá que estar atentos a la información que se vaya decantando en las siguientes dos semanas.</p><p>“Virgo, el 2025 es el momento de crecer, avanzar, ir por lo suyo; si había obstáculos en sus vidas, esos obstáculos se corrieron”, anima la astróloga. </p>"
            }
        ]
    },
    {
        "signZodiac": "Libra",
        "fechaDesde": "0924",
        "fechaHasta": "1023",
        "description": "Predicciones 2025 de Libra para el amor y el dinero",
        "prediction": 
        [
            {
                "title": "Horóscopo 2025 para Libra",
                "content": "<p>Las predicciones de 2025 para Libra anuncian mucha actividad en su signo y en el opuesto complementario, Aries. La astróloga Lourdes Ferro precisa las fechas y los tránsitos planetarios más importantes para el signo de balanza.</p><p>En general, Ferro indica que Libra vivirá un “gran alivio” este año e incluso acabará convirtiéndose en una fuente de inspiración para otros.</p><p>Según la experta, los tres signos de aire: Géminis, Libra y Acuario, se verán muy beneficiados en el 2025. </p>"
            },
            {
                "title": "Predicciones 2025 de Libra para el amor y el dinero la primera mitad del año",
                "content": "<p>Del 1 de enero al 9 de junio, con Júpiter en Géminis, Ferro afirma que los librianos “descubrirán una notable capacidad para enseñar y compartir conocimientos”, algo que los llevará a sentirse llamados a “guiar a otros, mostrando sus habilidades”.</p><p>El 13 de abril de 2025 se perfeccionará la Luna llena en Libra, una invitación clara a que se evalúen cómo lograron concretar los proyectos que sembraron hace tiempo, en la Luna nueva y el eclipse solar de octubre del 2024.</p><p>Hasta el 25 de mayo de 2025, Saturno directo en Piscis estará limitando su sector del trabajo y la salud. Sin embargo, a partir de ese día se liberarán con el planeta ubicándose en Aries. Para la astróloga “sentirán un gran alivio y se darán cuenta de que todo el trabajo hecho hasta el momento valió la pena”.</p>"
            },
            {
                "title": "Predicciones 2025 de Libra para el amor y el dinero la segunda mitad del año",
                "content": "<p>El 9 de junio, cuando Júpiter pasará a Cáncer, tampoco les dejará indiferentes. La especialista precisa que traerá “una energía expansiva y favorable en tu vida profesional”.</p><p>Si están buscando un ascenso, cambiando de carrera, o iniciando un nuevo proyecto por estas fechas, Ferro les dice que el éxito estará de su lado.</p><p>El 22 de septiembre de 2025, junto al equinoccio de primavera (en el hemisferio sur), el Sol se posicionará en su signo y dará inicio a su temporada.</p><p>Venus, su planeta regente, transitará su casa del 13 de octubre al 6 de noviembre. Sus vínculos, su amor por la belleza y la estética, la revisión de la complementariedad a la que tanto aspiran robarán toda su atención. En medio de este tránsito venusino disfrutarán de la Luna nueva en su signo el 21 de octubre de 2025.</p><p>Ferro concluye que, en el 2025, los nativos de Libra se darán cuenta de que hay que hacerle caso a lo que quieren y realmente darle tiempo al amor y a enamorarse de la vida. </p>"
            }
        ]
    },
    {
        "signZodiac": "Escorpio",
        "fechaDesde": "1024",
        "fechaHasta": "1122",
        "description": "Predicciones 2025 de Escorpio para el amor y el dinero",
        "prediction": 
        [
            {
                "title": "Horóscopo 2025 para Escorpio",
                "content": "<p>Las predicciones de 2025 para Escorpio anuncian un año “para abrazar el cambio con optimismo”. Así lo explica la astróloga Lourdes Ferro, quien precisa las fechas y los tránsitos planetarios más importantes que impactarán a los misteriosos escorpiones.</p><p>Ferro explica que su regente, Plutón, cambió de signo en noviembre del 2024, pero sus efectos comenzarán a verse mucho más este año y los siguientes. “La transformación que genera tu regente en Acuario hará de tu vida algo insólito”, dice.</p><p>Plutón es el planeta asociado a la muerte y la regeneración, así que al encontrarse con el disruptivo espíritu Acuario la revolución se vuelve una posibilidad segura. </p>"
            },
            {
                "title": "Predicciones 2025 de Escorpio para el amor y el dinero la primera mitad del año",
                "content": "<p>Del 1 de enero al 9 de junio de 2025 Júpiter en Géminis expandirá fuertemente los cambios en sus vidas y “les ayudará a ver las crisis como oportunidades de crecimiento”, explica la especialista.</p><p>El “gran benefactor” en el signo geminiano significará para Escorpio que los temas de la sexualidad, el poder y la pasión —con los que se siente tan identificado— se verán fuertemente estimulados y llevarán a estos nativos a explorar sin tabúes ni prejuicios.</p><p>El 12 de mayo de 2025 se dará la Luna llena en Escorpio. En este plenilunio se les ofrecerá la oportunidad de iluminar y sanar los rincones más profundos de sí mismos. Será una noche de gran intensidad emocional.  </p>"
            },
            {
                "title": "Predicciones 2025 de Escorpio para el amor y el dinero la segunda mitad del año",
                "content": "<p>A partir del 9 de junio, Júpiter pasará a Cáncer, otro signo de agua. Las energías centrales pondrán el foco en el mundo emocional e intuitivo que Escorpio conoce muy bien.</p><p>Ferro indica que el pasaje “estimulará ampliamente la necesidad de expansión en Escorpio”. Sus nativos, dice, sentirán un enorme magnetismo que hará que las barreras y los límites no se resistan a su paso”.</p><p>El 22 de septiembre de 2025 Marte, su antiguo regente, los visitará. Con su paso de un mes y medio les brindará un impulso arrollador a su energía transformadora.</p><p>Por eso, la astróloga aconseja que los escorpianos se pregunten qué lo que desean y cuáles son los asuntos que quieren conquistar. Si tienen claro su plan, podrían volverse una fuerza indetenible e hipnótica.</p><p>El 20 de noviembre de 2025 vivirán la Luna nueva en Escorpio para que siembren nuevos objetivos. Nadie mejor que ustedes para comprender que solo es posible avanzar si antes curamos nuestras heridas.</p><p>Para la especialista, este 2025 será un gran momento para que Escorpio haga contacto con el exterior y nutra su mente con nuevas ideas y experiencias. </p>"
            }
        ]
    },
    {
        "signZodiac": "Sagitario",
        "fechaDesde": "1123",
        "fechaHasta": "1222",
        "description": "Predicciones 2025 de Sagitario para el amor y el dinero",
        "prediction": 
        [
            {
                "title": "Horóscopo 2025 para Sagitario",
                "content": "<p>Las predicciones de 2025 dibujarán un año en el que Sagitario estará en primera fila. Es que Júpiter, su regente, será el planeta protagonista del año. La astróloga Lourdes Ferro precisa que el “gran benefactor” se ubicará seis meses en Géminis y seis meses en Cáncer.</p><p>Para Ferro, los centauros tendrán que seguir un consejo clave: “Géminis es su opuesto complementario. Por lo tanto, Júpiter, el planeta de la expansión desde enfrente, les va a ayudar a asociarse y a tener en cuenta que los otros pueden estar a tu favor. Pero ¡ojo!, tengan bastante cuidado de a quién le dan su palabra y en quién confían”.</p><p>Mirá las fechas y los tránsitos planetarios más importantes que impactarán a los optimistas sagitarianos.</p>"
            },
            {
                "title": "Predicciones 2025 de Sagitario para el amor y el dinero la primera mitad del año",
                "content": "<p>Del 1 de enero al 9 de junio de 2025, Júpiter desde Géminis hará más fluida la comunicación para los sagitarianos. Ferro adelanta que esto “les ayudará a fortalecer sus alianzas clave y potenciará su habilidad para conectar con los demás”.</p><p>“Los solteros del signo están de enhorabuena para formalizar una relación; si están en pareja podrán renovar su vínculo y fortalecer el amor entre ambos”, agrega la especialista.</p><p>El 11 de junio de 2025, se dará la Luna llena en Sagitario y podrán aprovechar toda la energía optimista y aventurera de la ocasión para evaluar qué objetivos lograron materializar o empezar a realizar desde la Luna nueva de diciembre de 2024.</p>"
            },
            {
                "title": "Predicciones 2025 de Sagitario para el amor y el dinero la segunda mitad del año",
                "content": "<p>A partir del 9 de junio de 2025, Júpiter ingresa en Cáncer y, para la astróloga, Sagitario “experimentará un fuerte impulso transformador” y “encontrará en su interior un nuevo 'yo' que desea emerger”.</p><p>Además, la segunda mitad del año se traducirá en un momento para renacer y “dar paso a nuevas experiencias”. En las predicciones del amor, Ferro adelanta que se encenderá “una llama intensa en la pasión y el sexo”, algo que enriquecerá sus relaciones.</p><p>El 29 de octubre de 2025, Mercurio entrará a su signo. Lo que impulsará su búsqueda de conocimiento y sabidurías. Poco después, el 9 de noviembre, Mercurio empezará a retrogradar en su signo, un tránsito que durará 20 días.</p><p>Antes de que culmine el 2025, el 19 de diciembre, podrán aprovechar de la Luna nueva en Sagitario. </p>"
            }
        ]
    },
    {
        "signZodiac": "Capricornio",
        "fechaDesde": "1223",
        "fechaHasta": "0120",
        "description": "Predicciones 2025 de Capricornio para el amor y el dinero",
        "prediction": 
        [
            {
                "title": "Horóscopo 2025 para Capricornio",
                "content": "<p>Las predicciones de 2025 para Capricornio anuncian que su energía estará en constante movimiento. En especial, porque su planeta regente, Saturno, se moverá en varias ocasiones a lo largo de los doce meses.</p><p>La astróloga Lourdes Ferro enumera cuáles serán las fechas y los tránsitos planetarios más importantes para los perseverantes capricornianos.</p>"
            },
            {
                "title": "Predicciones 2025 de Capricornio para el amor y el dinero la primera mitad del año",
                "content": "<p>Del 1 de enero al 9 de junio de 2025, Júpiter en Géminis impulsará a Capricornio a que escuchen a su cuerpo y entiendan los mensajes que le está enviando. Será un periodo en el que la salud y el trabajo cobrarán mayor importancia.</p><p>Durante el primer semestre, la astróloga aconseja que ordenen sus tareas, prioricen las más importantes y deleguen aquellas que los exceden. Por el espíritu rígido de este signo, quizá sea un gran desafío, pero si no lo hacen, seguirán cargando con todas las responsabilidades a su alrededor.</p><p>El 25 de mayo de 2025, Saturno hará su primera visita a Aries en más de 25 años. Por algunos meses, su regente se contagiará del ímpetu y el coraje de los arianos. La astróloga indicó que se trata de una gran oportunidad para tomar el control de nuestras vidas con determinación.</p>"
            },
            {
                "title": "Predicciones 2025 de Capricornio para el amor y el dinero la segunda mitad del año",
                "content": "<p>A partir del 9 de junio de 2025, Júpiter en Cáncer, su signo opuesto complementario, abrirá las puertas a los capricornianos a nuevas asociaciones, “llenas de optimismo y potencial de crecimiento”, dice la astróloga.</p><p>Ferro incluso considera que el tránsito del “gran benefactor” en el signo del cangrejo hará que Capricornio encuentre “la ayuda necesaria y vínculos que enriquezcan su desarrollo”.</p><p>Al mes siguiente, el 10 de julio de 2025, se perfeccionará la Luna llena en Capricornio. Una gran oportunidad para revisar las metas que planificaron en diciembre. La lunación marcará el punto culminante de un periodo de seis meses.</p><p>El 13 de julio, Saturno empezará a retrogradar en Aries y, poco más de un mes después, el 1 de septiembre, volverá al signo de Piscis. La astróloga indica que se traducirá en “una profunda revisión de nuestras estructuras emocionales y espirituales”.</p><p>El 28 de noviembre de 2025, Saturno dejará de retrogradar y continuará en el signo de los peces hasta febrero de 2026.</p><p>El 15 de diciembre de 2025, Marte, el planeta guerrero, ingresará a su signo y servirá de preámbulo para que reciban con ímpetu al Sol, el 21 de diciembre, con el solsticio de verano (en el hemisferio sur). Esas semanas disfrutarán de su temporada para evaluar las acciones que concretaron y trazar nuevos proyectos antes de que empiece el 2026. </p>"
            }
        ]
    },
    {
        "signZodiac": "Acuario",
        "fechaDesde": "0121",
        "fechaHasta": "0219",
        "description": "Predicciones 2025 de Acuario para el amor y el dinero",
        "prediction": 
        [
            {
                "title": "Horóscopo 2025 para Acuario",
                "content": "<p>Las predicciones de 2025 les auguran a los innovadores acuarianos un año con varias sorpresas, muchas de ellas comandadas por Urano, su regente. Así lo cuenta la astróloga Lourdes Ferro, quien agrega que habrá otros tránsitos planetarios destacados que dibujarán un panorama único.</p><p>Acuario dejó huella desde finales de 2024 con el paso de Plutón a su signo. Su impronta se extenderá a lo largo de los siguientes 20 años y el planeta de la regeneración se alimentará de la energía vanguardista, original y, a ratos, excéntrica de los acuarianos.</p><p>La astróloga enumera cuáles serán las fechas y los tránsitos planetarios más importantes para el signo de aire.</p>"
            },
            {
                "title": "Predicciones 2025 de Acuario para el amor y el dinero la primera mitad del año",
                "content": "<p>Del 1 de enero al 9 de junio de 2025, Júpiter en Géminis invitará a Acuario a explorar sus talentos, pasiones y creatividad. En palabras de Ferro, estos nativos se sentirán “más juguetones y aventureros, dispuestos a arriesgarse en el amor”.</p><p>El 27 de enero de 2025 recibirán a Mercurio y, dos días después, el 29 de enero, podrán arrancar el año con buen pie gracias a la Luna nueva en su signo. Recuerden que lo que siembren en esta lunación podrán desarrollarlo los siguientes seis meses.</p><p>Entre las predicciones del amor, el primer semestre “será un gran momento para enamorarse, brillar y disfrutar de la magia que habita a su alrededor”, dice la astróloga.</p>"
            },
            {
                "title": "Predicciones 2025 de Acuario para el amor y el dinero la segunda mitad del año",
                "content": "<p>Del 9 de junio hasta finales de 2025, “el año se volverá un poco más riguroso” con Acuario. Ferro indica que Júpiter en Cáncer les recordará que “no todo es diversión en la vida” y les animará a priorizar su bienestar.  El “gran benefactor” les invitará a que evalúen su rutina y su estilo de vida mientras transite por aguas cancerianas.</p><p>Del 7 de julio al 7 de noviembre de 2025, Urano, su regente, se ubicará en Géminis. La astróloga lo describe como “un torbellino de energía disruptiva y emocionante”. Tocarán a su puerta diversas situaciones que les animen a salir de los convencionalismos en la forma de comunicarse y de pensar.</p><p>El 9 de agosto de 2025 se perfeccionará la Luna llena en Acuario mientras el Sol se ubicará en su signo opuesto complementario, Leo. Será una gran oportunidad para iluminar lo que lograron construir con sus ideas.</p><p>Lourdes Ferro resume que el 2025, los movimientos otro planeta, Saturno, les ofrecerán claridad mental y un reencuentro con su economía para que experimenten una sensación de libertad para crear. </p>"
            }
        ]
    },
    {
        "signZodiac": "Piscis",
        "fechaDesde": "0320",
        "fechaHasta": "0320",
        "description": "Predicciones 2025 de Piscis para el amor y el dinero",
        "prediction": 
        [
            {
                "title": "Horóscopo 2025 para Piscis",
                "content": "<p>Las predicciones de 2025 colocan a Piscis como uno de los signos más movilizados. La astróloga Lourdes Ferro detalla que el intuitivo mar pisciano formará parte de dos movimientos planetarios cruciales: los pasajes de Neptuno, su regente, y de Saturno.</p><p>Cuáles serán las fechas y los tránsitos planetarios más importantes para Piscis y qué influencia tendrá en este signo el cambio de nodos lunares.</p>"
            },
            {
                "title": "Predicciones 2025 de Piscis para el amor y el dinero la primera mitad del año",
                "content": "<p>El 2025 empezará con Neptuno y Saturno (ambos en Piscis) configurando las energías del intuitivo signo. Además, Ferro precisa que la permanencia de Júpiter en Géminis del 1 de enero al 9 de junio de 2025 impactará en los nativos en su área de la familia.</p><p>“Júpiter desde Géminis genera movimiento en sus cimientos —dice la astróloga—. El hogar y la familia se ven influenciadas. Tendrán la oportunidad de profundizar en las relaciones familiares, de realizar modificaciones en su hogar o bien de mudarse”.</p><p>El 29 de enero de 2025 se producirá el cambio de eje nodal, los dos puntos de coincidencia entre la órbita de la Luna y la de la Tierra. El eje se posicionará en la dupla Piscis – Virgo, donde ocurrirán tres de los cuatro eclipses del 2025.</p><p>El 27 de febrero vivirán la Luna nueva en Piscis para que aprovechen su energía y siembren la conquista de nuevos territorios.</p><p>El 14 de marzo de 2025 recibirán la energía frente a ustedes, pues la Luna llena y eclipse lunar ocurrirán en Virgo, su opuesto complementario. La astróloga considera que será un portal energético para que los piscianos observen muy bien su área vincular.</p><p>El 30 de marzo, su regente Neptuno, el planeta de la fantasía y la imaginación, saldrá de su signo rumbo a Aries. El cambio les pedirá que todo lo que han proyectado lo lleven a la acción en los próximos meses.</p><p>Desde enero hasta el 25 de mayo de 2025, Saturno en Piscis, como un riguroso maestro, les habrá formado para que trabajasen con ahínco en sus ideas, señala la astróloga. A mediados de año comenzarán a cosechar lo que hayan sembrado.</p>"
            },
            {
                "title": "Predicciones 2025 de Piscis para el amor y el dinero la segunda mitad del año",
                "content": "<p>A partir del 9 de junio de 2025, cuando Júpiter ingresará a Cáncer, el planeta expandirá la creatividad y la expresión personal de Piscis, señala la astróloga.</p><p>La segunda mitad del año, el romance y el placer tocarán a sus puertas. Como consejo, la especialista recomienda que los piscianos conecten con su niño interior y den rienda suelta a la creatividad que les caracteriza. La magia en la que tanto creen se hará presente en cada una de sus acciones.</p><p>El 1 de septiembre, Saturno volverá retrógrado a Piscis y les colocará un último desafío antes de abandonarlos en 2026. Ferro explica que esta marcha atrás seguramente traerá preguntas acerca de las estructuras de creencias que atesoran y sobre su propia identidad.</p><p>Unos días después, el 7 de septiembre de 2025, vivirán la Luna llena y eclipse lunar en Piscis. El penúltimo eclipse del año invitará a los místicos peces a conectar con la intuición y lo intangible. Lo que aprendan entonces les servirá para los últimos meses del año. </p>"
            }
        ]
    }
]

export const getYearPredictionsZodiac2025 = () => {
  
    const result = dataZodiacPredictions.map((item, index) =>{
        let signZodiac = item.signZodiac;
        let fechaDesde = item.fechaDesde;
        let fechaHasta = item.fechaHasta;
        let description = item.description;
        let characteristics = item.prediction.map(prediction => {
            return {
                title: prediction.title,
                content: prediction.content
            };
        });

      return { 
          signZodiac,
          fechaDesde,
          fechaHasta,
          description,
          characteristics
        };
    })  

    return result
  }