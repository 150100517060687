import useAppTrue from './useAppTrue';
import useContainerWidget from './useContainerWidget';
import useElementOnScreem from './useElementOnScreem'
import useForm from './useForm'
import useGetLocation from './useGetLocation';
import useGetMoneyPrice from './useGetMoneyPrice';
import useKeyPress from './useKeyPress';
import useLastCover from './useLastCover';
import useMostViewed from './useMostViewed';
import useNewsDataModules from './useNewsDataModules';
import useNewsletter from './useNewsletter';
import useSetAcTiveScroll from './useSetAcTiveScroll';
import useSetPageLastPage from './useSetPageLastPage';
import UseSupportedBrowser from './UseSupportedBrowser';


export {
    useAppTrue,
    useContainerWidget,
    useElementOnScreem,
    useForm,
    useGetLocation,
    useGetMoneyPrice,
    useKeyPress,
    useLastCover,
    useMostViewed,
    useNewsDataModules,
    useNewsletter,
    useSetAcTiveScroll,
    useSetPageLastPage, 
    UseSupportedBrowser
}