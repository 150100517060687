import { Service } from "models/Service";
import { getPrevDateNotWeekend, parseDataCurrencies, parseDataBonds } from "helpers";
export class CurrencyBond extends Service {
  constructor() {
    super({
      adsPath: "servicios/divisas",
      meta: {
        description: `Los principales indicadores económicos de Argentina y el mundo. Noticias del dólar, Merval, empresas argentinas, acciones y bonos de deuda.`,
        keywords: "bonos, bolsa, moneda, dolar, euro, dolar blue",
        title: `Divisas, Acciones y Bonos - Clarin.com`,
        url: "economia/divisas-acciones-bonos",
        extra: [],
      },
      section: "economia",
      sectionAds: "divisas",
      template: "CurrenciesBonds",
      title: `Dólar blue, dólar hoy, euro, real, cripto, divisas, acciones y bonos`,
      titleHeader: "Dólar, euro, real, acciones y bonos hoy",
      slug: "dolar",
      breadcrumb: [
        {
          title: "Divisas, Acciones y Bonos",
          url: "/economia/divisas-acciones-bonos",
        }
      ]
    });
  }

  formatBlueTouristPrev = (blueTourist) => {
    return {
      blue_compra: blueTourist?.data?.blue_compra ?? "0",
      blue_venta: blueTourist?.data?.blue_venta ?? "0",
      dolar_turista: blueTourist?.data?.dolar_turista ?? "0",
    };
  };

  getBond = async () => {
    const { data } = await this.fetchData(
      `${process.env.NEXT_PUBLIC_API_CONTENTS}/api/mercados/bonos`
    );
    return data?.items ?? [];
  };

  getCurrency = async () => {
    const { data } = await this.fetchData(
      `${process.env.NEXT_PUBLIC_API_CONTENTS}/api/mercados/monedas`
    );
    return data?.items ?? [];
  };

  getMervalPanel = async () => {
    const { data } = await this.fetchData(
      `${process.env.NEXT_PUBLIC_API_CONTENTS}/api/mercados/paneles`
    );
    return data?.items ?? [];
  };

  getMervalValue = async () => {
    const { data } = await this.fetchData(
      `${process.env.NEXT_PUBLIC_API_CONTENTS}/api/mercados/indices`
    );
    return data?.items ?? [];
  };

  getUsdBlueTourist = async () => {
    const { data } = await this.fetchData(
      `${process.env.NEXT_PUBLIC_API_CONTENTS}/api/mercados/redaccion/latest`
    );
    let items = data?.items ?? [];
    if (items?.[0]?.createdAt) {
      const prevDate = getPrevDateNotWeekend(items[0].createdAt);
      let dataPrevDate = await this.fetchData(
        `${process.env.NEXT_PUBLIC_API_CONTENTS}/api/mercados/redaccion/all?date=${prevDate.replace(/\//g, "-")}`
      );
      dataPrevDate = dataPrevDate?.data?.items?.length
        ? this.formatBlueTouristPrev(dataPrevDate.data.items[dataPrevDate.data.items.length - 1])
        : [];
      items[0].prevDate = dataPrevDate;
    }
    return items;
  };

  formatBlueTouristPrev = (blueTourist) => {
    return {
      blue_compra: blueTourist?.data?.blue_compra ?? "0",
      blue_venta: blueTourist?.data?.blue_venta ?? "0",
      dolar_turista: blueTourist?.data?.dolar_turista ?? "0",
      bolsa_compra: blueTourist?.data?.bolsa_compra ?? "0",
      bolsa_venta: blueTourist?.data?.bolsa_venta ?? "0",
      contado_con_liqui_compra: blueTourist?.data?.contado_con_liqui_compra ?? "0",
      contado_con_liqui_venta: blueTourist?.data?.contado_con_liqui_venta ?? "0",
      dolar_bullexchange_compra: blueTourist?.data?.dolar_bullexchange_compra ?? "0",
      dolar_bullexchange_venta: blueTourist?.data?.dolar_bullexchange_venta ?? "0",
    };
  };

  parseData = async (query) => {
    let dataFront = null;
    const slug = this.getSlug();
    if (query?.[3]) {
      this.setMeta(this.parseMeta({  
        url: `economia/${query?.[1]}/${query?.[2]}/${query?.[3]}`, 
      }))
    }
    if (query?.[1] === "calculadora-ganancias") {

      this.setTemplate("IncomeTaxCalculator");
      this.setMeta(this.parseMeta({
        description: `Calculadora de ganancias - Clarín.com`,
        keywords: "calculadora, ganancias",
        title: `Calculadora de ganancias`,
        url: `economia/calculadora-ganancias`,
        extra: []
      }));
      this.setTitle('Calculadora de ganancias');
      this.setSlug('impuesto-a-las-ganancias');
      this.setSectionAds("calculadora-ganancias");
      this.setBreadcrumb([
        {
          title: "Economía",
          url: "/economia",
        },
        {
          title: "Calculadora de ganancias",
          url: "",
        }
      ])

    } else if (query?.[1] === "calculadora-inflacion") {

      this.setTemplate("PaymentCalculation");
      this.setMeta(this.parseMeta({
        description: `Calculadora de inflación en Argentina: consultá si te conviene pagar de contado o en cuotas - Clarín.com`,
        keywords: "calculadora, inflacion",
        title: `Calculadora de inflación en Argentina: consultá si te conviene pagar de contado o en cuotas`,
        url: `economia/calculadora-inflacion`,
        extra: []
      }));
      this.setTitle('Calculadora de inflación en Argentina: consultá si te conviene pagar de contado o en cuotas');
      this.setSectionAds("calculadora-inflacion");
      this.setBreadcrumb([
        {
          title: "Economía",
          url: "/economia",
        },
        {
          title: "Calculadora de inflación",
          url: "",
        }
      ])

    } else if (query?.[1] === "calculadora-aguinaldo-cuanto-dinero-extra-vas-recibir") {

      dataFront = {
        ...dataFront,
        bonus_calculator: {
          description: 'Calculadora de aguinaldo y aguinaldo proporcional a los meses trabajados para empleados de comercio en Argentina, con cálculos de montos netos y brutos.',
          keywords: "calculadora, aguinaldo",
          short_title: 'Calculadora de aguinaldo',
          slug: query?.[1],
          text: '¿Tenés dudas acerca de cuánto dinero vas a recibir? Nuestra calculadora de aguinaldo te da un cálculo confiable. ¡Simplificá tu vida financiera y tomá el control de tus finanzas!',
          title: 'Calculadora de Aguinaldo (Sueldo Anual Complementario) Online, y SAC proporcional',
          iframe: '//calculadora-aguinaldo.agea.com.ar/'
        }
      }

      this.setTemplate("BonusCalculator");
      this.setMeta(this.parseMeta({
        description: `${dataFront.bonus_calculator.description} - Clarín.com`,
        keywords: `${dataFront.bonus_calculator.keywords}`,
        title: `${dataFront.bonus_calculator.title}`,
        url: `economia/${dataFront.bonus_calculator.slug}`,
        extra: []
      }));
      this.setTitle(`${dataFront.bonus_calculator.title}`);
      this.setSectionAds(`${dataFront.bonus_calculator.slug}`);
      this.setBreadcrumb([
        {
          title: "Economía",
          url: "/economia",
        },
        {
          title: `${dataFront.bonus_calculator.short_title}`,
          url: "",
        }
      ])
    } else if (query?.[1] === "calculadora-monotributo") {
      this.setTemplate("SingleTaxCalculator");
      this.setMeta(this.parseMeta({
        description: `Calculadora monotributo - Clarín.com`,
        keywords: "calculadora, monotributo",
        title: `Calculadora monotributo`,
        url: `economia/calculadora-monotributo`,
        extra: []
      }));
      this.setTitle('Calculadora monotributo');
      this.setSlug('monotributo');
      this.setSectionAds("calculadora-monotributo");
      this.setBreadcrumb([
        {
          title: "Economía",
          url: "/economia",
        },
        {
          title: "Calculadora monotributo",
          url: "",
        }
      ])
    } else if (query?.[1] === "calculadora-alquileres") {

      this.setTemplate("CalculatorRentals");
      this.setMeta(this.parseMeta({
        description: `Calculadora de alquileres - Clarín.com`,
        keywords: "calculadora, alquileres",
        title: `Calculadora de alquileres`,
        url: `economia/calculadora-alquileres`,
        extra: []
      }));
      this.setTitle('Calculadora de alquileres');
      this.setSlug('impuesto-a-las-alquileres');
      this.setSectionAds("calculadora-alquileres");
      this.setBreadcrumb([
        {
          title: "Economía",
          url: "/economia",
        },
        {
          title: "Calculadora de alquileres",
          url: "",
        }
      ])

    } else {

      const bonds = await this.getBond();
      const currency = await this.getCurrency();
      const mervalPanel = await this.getMervalPanel();
      const mervalValue = await this.getMervalValue();
      const usdBlueTourist = await this.getUsdBlueTourist();
      dataFront = { bonds, currency, mervalPanel, mervalValue, usdBlueTourist }      
    }

    const notes = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/latest/?type=lilanews&tag=${slug}&limit=6`);
    this.setData({
      ...dataFront,
      notes: notes?.data?.items ?? [],
      coin: "",
      bond: ""
    });
    if (query[2] === "monedas") {
      this.setTitleHeaderLink("/economia/divisas-acciones-bonos");
      this.setTitleHeaderTag("p");      
      const sectionAds = query[3] && query[3].replace("-", "")
      this.setSectionAds(sectionAds)
      const { primary } = parseDataCurrencies(dataFront.currency, dataFront.usdBlueTourist);
      const coin = primary.filter(
        (value) => {
          if (value?.url == query[3]) {
            return value;
          };
        }
      );
      if (coin[0]) {
        this.setTemplate("Currencies");
        this.setBreadcrumb([
          {
            title: "Divisas, Acciones y Bonos",
            url: "/economia/divisas-acciones-bonos",
          },
          {
            title: coin[0].description,
            url: "",
          }
        ])
        this.setData({
          ...dataFront,
          notes: notes?.data?.items ?? [],
          coin: coin[0] ? coin[0] : "",
          bond: ""
        });
        this.setTitle(`${coin[0].name} hoy, cotización y precio del ${coin[0].name} | Clarín`);
        this.setMeta(this.parseMeta({
          keywords: "Dólar, Bonos, Bolsa, Moneda, Divisas, bonos, Dólar oficial, Dólar blue, Dólar turista, Dólar MEP, Dólar CCL, Calculadora de Ganancias, Calculadora de inflación",
          description: `${coin[0].name}. Los principales indicadores económicos de Argentina y el mundo. Noticias del dólar, Merval, empresas argentinas, acciones y bonos de deuda.`,
          url: `economia/${query?.[1]}/${query?.[2]}/${query?.[3]}`, 
        }));
      }
    }

    if (query[2] === "bonos") {
      this.setTitleHeaderLink("/economia/divisas-acciones-bonos");
      this.setTitleHeaderTag("p");
      this.setTitle(`${query[3]}, divisas, acciones y bonos`);
      this.setMeta(this.parseMeta({
        keywords: "Dólar, Bonos, Bolsa, Moneda, Divisas, bonos, Dólar oficial, Dólar blue, Dólar turista, Dólar MEP, Dólar CCL, TVPY, Calculadora de Ganancias, Calculadora de inflación",
        description: `${query[3]}. Los principales indicadores económicos de Argentina y el mundo. Noticias del dólar, Merval, empresas argentinas, acciones y bonos de deuda.`,
        url: `economia/${query?.[1]}/${query?.[2]}/${query?.[3]}`, 
      }));
      const sectionAds = query[3] && query[3].replace("-", "")
      this.setSectionAds(sectionAds)
      const bondsData = parseDataBonds(dataFront.bonds);
      const bond = bondsData.filter(
        (value) => {
          if (value?.papel == query[3]) {
            return value;
          };
        }
      );
      if (bond[0]) {
        this.setTemplate("Bonds");
        this.setBreadcrumb([
          {
            title: "Divisas, Acciones y Bonos",
            url: "/economia/divisas-acciones-bonos",
          },
          {
            title: "Bonos",
            url: "/economia/divisas-acciones-bonos",
          },
          {
            title: bond[0].papel,
            url: "",
          }
        ])
        this.setData({
          ...dataFront,
          notes: notes?.data?.items ?? [],
          coin: "",
          bond: bond[0]
        });
      }
    }
  };
}
